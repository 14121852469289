"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.json.stringify.js");

var _objectSpread2 = _interopRequireDefault(require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/esm/objectSpread2.js"));

var _stationSetting = require("@/assets/js/stationSetting");

var _vuex = require("vuex");

var _account = require("@/model/user/account");

var _message = require("@/components/dialog/message");

var _CheckAccount = _interopRequireDefault(require("./components/CheckAccount"));

var _ConfirmPwd = _interopRequireDefault(require("./components/ConfirmPwd"));

var _i18n = require("@/assets/js/i18n");

var _vuePuzzleVcode = _interopRequireDefault(require("vue-puzzle-vcode"));

var _ = _interopRequireDefault(require("../../assets/img/rePhoto/1.png"));

var _2 = _interopRequireDefault(require("../../assets/img/rePhoto/2.png"));

var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));

var _Geetest = _interopRequireDefault(require("@/components/Geetest.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import InputNumber from "@/components/common/InputNumber";
//页面内引用插件
var _default = {
  data: function data() {
    return {
      img1: _.default,
      img2: _2.default,
      imgs: [],
      isShow: false,
      forgetData: {},
      forgetPwdLoading: false,
      only_name: "",
      isInCheck: false,
      isInChange: false,
      validInfo: {},
      formRules: {
        account: [{
          required: true,
          message: this.translateTitle("\u90AE\u7BB1/\u624B\u673A\u53F7/\u7528\u6237\u540D\u4E0D\u80FD\u4E3A\u7A7A"),
          trigger: "change"
        }]
      },
      isShowGeet: false,
      geetParam: null,
      pwdToken: ""
    };
  },
  components: {
    ConfirmPwd: _ConfirmPwd.default,
    CheckAccount: _CheckAccount.default,
    Vcode: _vuePuzzleVcode.default,
    Geetest: _Geetest.default // InputNumber

  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)({
    language: "user/language"
  })),
  created: function created() {
    if (_stationSetting.VUE_APP_ID == "AwyOTFRlsfQ5mRkqwCNaEd5T" || _stationSetting.VUE_APP_ID == "DwyOTFRlsfQ5mRkqwCNaEd5T" || _stationSetting.VUE_APP_ID == "J8tstegyx2UjYzJ6J1vNuD3w") {
      this.imgs.push(_.default);
    } else {
      this.imgs.push(_2.default);
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    translateTitle: _i18n.translateTitle,
    onFilterAccount: function onFilterAccount(val) {
      var _this = this;

      this.$nextTick(function () {
        _this.$refs.checkForm.validateField("account");
      });
    },
    onSecret: function onSecret(val) {
      this.pwdToken = val; // this.only_name = val;

      this.isInChange = true;
      this.isInCheck = false;
    },
    success: function success(geetParam) {
      this.geetParam = geetParam;
      this.onSubmit();
      this.isShow = false; // 通过验证后，手动隐藏模态框
    },
    onCloseVcode: function onCloseVcode() {
      this.isShow = false;
    },
    onCancel: function onCancel() {
      this.isInCheck = false;
    },
    onShowVcode: function onShowVcode() {
      var _this2 = this;

      this.$refs.checkForm.validate(function (valid) {
        if (valid) {
          // this.isShow = true;
          _this2.isShowGeet = !_this2.isShowGeet;
        } else {
          _this2.isShow = false;
        }
      });
    },
    onSubmit: function onSubmit() {
      var _this3 = this;

      this.$refs.checkForm.validate(function (valid) {
        if (valid) {
          var data = (0, _objectSpread2.default)({
            login_name: _this3.forgetData.account
          }, _this3.geetParam);
          _this3.forgetPwdLoading = true;
          (0, _account.forgotPwdCheckAccountGeet)(data).then(function (rs) {
            _this3.forgetPwdLoading = false;

            if (rs.status == 200) {
              var _rs$data = rs.data,
                  validate_email = _rs$data.validate_email,
                  validate_google = _rs$data.validate_google,
                  validate_mobile = _rs$data.validate_mobile;

              if (validate_email == 0 && validate_google == 0 && validate_mobile == 0) {
                (0, _message.showMessage)({
                  message: "账号未绑定谷歌或邮箱，无法重置密码"
                });
                return;
              } else {
                _this3.isInCheck = true;
                _this3.validInfo = rs.data;
                sessionStorage.setItem("user_info", JSON.stringify(_this3.validInfo));
              }
            }
          });
        }
      });
    }
  }
};
exports.default = _default;