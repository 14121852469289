var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"confirmPwd"},[_c('el-form',{ref:"confirm_ref",attrs:{"model":_vm.pwdData,"rules":_vm.formRules},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-form-item',{class:{ pwd: true, showLevel: _vm.showLevel },attrs:{"prop":"pwd"}},[_c('el-input',{directives:[{name:"focus",rawName:"v-focus",value:(_vm.pwdFocus),expression:"pwdFocus"}],attrs:{"autocomplete":"off","placeholder":_vm.translateTitle('新密码'),"type":"password","maxlength":"20"},on:{"focus":function($event){_vm.showLevel = true},"blur":_vm.pwdBlur,"input":_vm.checkChange},model:{value:(_vm.pwdData.pwd),callback:function ($$v) {_vm.$set(_vm.pwdData, "pwd", $$v)},expression:"pwdData.pwd"}}),(_vm.showLevel)?_c('div',{staticClass:"pwd-level"},[_c('p',{staticClass:"level-bar"},[_c('span',{class:{
              'gray-bar': true,
              'red-bar': _vm.pwdLevel == 1,
              'orange-bar': _vm.pwdLevel == 2,
              'green-bar': _vm.pwdLevel == 3,
            }}),_c('span',{class:{
              'gray-bar': true,
              'orange-bar': _vm.pwdLevel == 2,
              'green-bar': _vm.pwdLevel == 3,
            }}),_c('span',{class:{ 'gray-bar': true, 'green-bar': _vm.pwdLevel == 3 }}),_c('i',{class:{
              'level-text': true,
              redC: _vm.pwdLevel == 1,
              orangeC: _vm.pwdLevel == 2,
              grrenC: _vm.pwdLevel == 3,
            }},[_vm._v(_vm._s(_vm.translateTitle(_vm.levelText)))])]),_c('div',{staticClass:"level-text"},[_c('p',[_c('i',{class:{ 'green-spot': _vm.spotLevel.digit }}),_vm._v(" "+_vm._s(_vm.translateTitle("6-20位字符"))+" ")]),_c('p',[_c('i',{class:{ 'green-spot': _vm.spotLevel.enNum }}),_vm._v(" "+_vm._s(_vm.translateTitle("必须包含字母和数字，不能包含空格"))+" ")]),_c('p',[_c('i',{class:{ 'green-spot': _vm.spotLevel.symbol }}),_vm._v(" "+_vm._s(_vm.translateTitle("建议包含标点符号"))+" ")])])]):_vm._e()],1),_c('el-form-item',{staticClass:"confirm-pwd",attrs:{"prop":"confirmPwd"}},[_c('el-input',{directives:[{name:"focus",rawName:"v-focus",value:(_vm.confirmFocus),expression:"confirmFocus"}],class:{ confirmOutline: _vm.confirmOutline },attrs:{"autocomplete":"off","placeholder":_vm.translateTitle('确认密码'),"type":"password","maxlength":"20"},model:{value:(_vm.pwdData.confirmPwd),callback:function ($$v) {_vm.$set(_vm.pwdData, "confirmPwd", $$v)},expression:"pwdData.confirmPwd"}})],1),_c('el-button',{staticClass:"reset-btn theme-btn",attrs:{"loading":_vm.resetLoading},on:{"click":_vm.resetPwd}},[_vm._v(_vm._s(_vm.translateTitle("重置密码")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }